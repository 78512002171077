<template>
  <div
    class="team-info-container drawer-detection-container"
    :class="drawer ? 'drawer-open' : 'drawer-closed'"
  >
    <div v-if="showProgressCircular"
         class="team-spinner-height d-flex justify-center align-center"
    >
      <v-progress-circular
        :size="70"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!showProgressCircular">
      <v-toolbar class="team-short-info-container">
        <v-toolbar-title
          v-if="selectedTeam"
          class="team-title-display mt-2"
        >
          <div class="d-flex align-center flex-wrap">
            <div class="team-name-overflow">
              Team "{{ selectedTeam.name }}"
            </div>
            <div class="d-flex align-center flex-wrap mb-2">
              <TeamStatus
                class="team-status ml-2 mt-1 mr-2"
                :pSelectedTeam="selectedTeam"
                :pLoading="loading"
                @reloadData="fetchTeam()"
              />
              <TeamManagerState
                class="mr-1 mt-1"
                :pSelectedTeam="selectedTeam"
                :pManager="teamStats.manager"
              />
              <TeamHoldStateComponent
                :pTeam="selectedTeam"
                @onUpdate="fetchTeam()"
              />
            </div>
          </div>
          <TeamStats />
        </v-toolbar-title>
        <template v-slot:extension>
          <v-tabs v-model="tabControl">
            <v-tabs-slider color="accent"></v-tabs-slider>
            <v-tab
              class="ml-0 item-display"
              v-for="(item, index) in tabItems"
              :key="index"
              @click="changeTab(item, index)"
            >
              {{ item.display }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tabControl" class="tabs-items-background">
        <v-tab-item>
          <v-container fluid class="team-data ml-0 mr-0">
            <CustomAddEditComponent
              ref="customAddEditComponent"
              v-if="selectedTeam"
              class="team-component"
              :pCardTitle="selectedTeam ? `Team '${selectedTeam.name}'` : ''"
              :pEditedItem="selectedTeam"
              :pEditedItemType="TEAM_ADD_EDIT_ITEM"
              :pItemParams="TEAM_PARAMS"
              :pLoading="loading"
              :pDisabledParams="disabledParams"
              :pShowActions="!selectedTeam.isTerminated"
              :pBeforeItemCreatedOrUpdated="openConfirmationManagerDialogOrSaveTeam"
              @onItemCreatedOrUpdated="updateAndReloadTeam($event)"
            />
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <TeamMemberships :pSelectedTeam="selectedTeam" />
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <Nominations @onReloadData="fetchTeam()" :pSelectedTeam="selectedTeam" />
          </v-container>
        </v-tab-item>
      </v-tabs-items>

      <ConfirmationDialog ref="confirmationDialog" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Vue } from "vue-property-decorator";
import {
  dismissAction,
  logAndExtractMessage,
  TEAM_ADD_EDIT_ITEM,
  TEAM_PARAMS,
} from "@/utils/utils";

export default {
  name: "TeamPage",
  components: {
    CustomAddEditComponent: () => import("../custom-components/AddEditComponent.vue"),
    Nominations: () => import("./Nominations.vue"),
    ConfirmationDialog: () => import("../dialogs/ConfirmationDialog.vue"),
    TeamStatus: () => import("../custom-components/TeamStatusComponent.vue"),
    TeamManagerState: () => import("../custom-components/TeamManagerStateComponent.vue"),
    TeamStats: () => import("./TeamStats.vue"),
    TeamHoldStateComponent: () => import("./TeamHoldStateComponent.vue"),
    TeamMemberships: () => import("@/components/team-page/team-memberships/TeamMemberships"),
  },

  data: () => ({
    TEAM_ADD_EDIT_ITEM,
    TEAM_PARAMS,

    disabledParams: ["managerEmail"],

    tabItems: [
      {
        display: "Team Info",
        route: "team-info",
      },
      {
        display: "Memberships",
        route: "memberships",
      },
      {
        display: "Nominations",
        route: "nominations",
      },
    ],

    tabControl: 0,

    selectedTeam: undefined,

    showProgressCircular: false,
    loading: false,
  }),

  computed: {
    ...mapState("coreModule", ["drawer"]),
    ...mapState("teamsModule", ["teamStats"]),

    tabIndex() {
      const foundTab = this.tabItems.find((item) => item.route === this.$route.query.tab);
      return this.tabItems.indexOf(foundTab) || 0;
    },
  },

  methods: {
    changeTab(tab) {
      if (tab.route !== this.$route.query.tab) {
        this.$router.push({
          path: this.$route.path,
          query: { tab: tab.route },
        });
        this.tabControl = this.tabIndex;
      }
    },

    openConfirmationManagerDialogOrSaveTeam(team) {
      if (team.managerEmail == this.selectedTeam.managerEmail) {
        return true;
      }
      this.$refs.confirmationDialog.openDialog({
        header: "Confirm manager",
        text: team.managerEmail
          ? `
            The manager is responsible to load and maintain the application for the Team.
            Once you nominate someone to be the Team Manager they will have 5 days to accept
            or decline the nomination before it expires.
            Once the manager ${team.managerEmail} accepts the role they can not be removed without recreating the team.
          `
          : "Are you sure you want to clear the manager?",
        submitText: "Confirm",
        submitColor: "green",
        onSubmit: () => {
          this.updateAndReloadTeam(team);
          this.$refs.customAddEditComponent.disableComponent();
        },
      });
      return false;
    },

    async updateAndReloadTeam(team) {
      this.loading = true;
      try {
        await this.$store.dispatch("teamsModule/addOrSaveTeam", team);
        await this.fetchTeam();
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
      this.loading = false;
    },

    async fetchTeam() {
      this.loading = true;
      this.tabControl = this.tabIndex;

      const teamId = this.$route.params.id;

      try {
        const [team] = await Promise.all([
          this.$store.dispatch("teamsModule/loadTeamById", teamId),
          this.$store.dispatch("teamsModule/loadTeamStats", teamId),
        ]);
        this.selectedTeam = team;
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      this.loading = false;
    },

    clearDataInComponents() {
      this.selectedTeam = undefined;
      this.$store.commit("nominationModule/updateCurrentPageNominations", []);
      this.$store.commit("teamsModule/updateTeamStats", undefined);
      this.$store.commit("acceptedMemberNominationsModule/updateCurrentPageNominations", []);
      this.$store.commit("teamMembershipsModule/clearTeamMemberships");
    },
  },

  async mounted() {
    this.showProgressCircular = true;

    this.clearDataInComponents();

    if (!this.$route.query.tab) {
      this.$router.push({
        path: this.$route.path,
        query: { tab: this.tabItems[0].route },
      });
    }

    this.tabControl = this.tabIndex;

    await this.fetchTeam();

    this.showProgressCircular = false;
  },
};
</script>

<style lang="scss">
.team-info-container {
  .manager-name-link {
    color: var(--v-primary-base) !important;
    text-decoration: underline !important;
  }
  .text-style {
    font-size: 14px;
  }

  .team-short-info-container {
    height: auto !important;
    width: 100%;
    header,
    .v-toolbar__content,
    .v-toolbar__title,
    {
      height: auto !important;
      width: 100%;
    }
  }

  .team-title-display {
    width: 100%;

    .team-name-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .team-status {
      margin-left: 5px;
    }
  }

  .team-spinner-height {
    height: 100vh;
  }

  .team-data {
    width: 100%;
  }

  .item-display {
    display: flex;
    align-items: center;
    text-decoration: none;

    div {
      color: var(--v-textColor-base) !important;
      height: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .team-info-container {
    .v-toolbar,
    .v-toolbar__content {
      height: auto !important;
    }

    .team-title-display {
      display: block !important;

      .team-status {
        margin-left: 0 !important;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .team-info-container {
    .team-data {
      width: 40%;
    }

  }
}
</style>
